export enum LocalStorageKeys {
  IS_INSTITUTIONS_LIST_ON_PARENTS_PAGE_EXPANDED = 'IS_INSTITUTIONS_LIST_ON_PARENTS_PAGE_EXPANDED',
  IS_INSTITUTIONS_LIST_ON_CHILDREN_PAGE_EXPANDED = 'IS_INSTITUTIONS_LIST_ON_CHILDREN_PAGE_EXPANDED',
  IS_CONTACTS_LIST_ON_CONTACTS_PAGE_EXPANDED = 'IS_CONTACTS_LIST_ON_CONTACTS_PAGE_EXPANDED',
  IS_CONTACTS_LIST_ON_PARENT_PAGE_EXPANDED = 'IS_CONTACTS_LIST_ON_PARENT_PAGE_EXPANDED',
  IS_CONTACTS_LIST_ON_CHILD_PAGE_EXPANDED = 'IS_CONTACTS_LIST_ON_CHILD_PAGE_EXPANDED',
}

export class LocalStorageService {

  private static defaults: Record<LocalStorageKeys, any> = {
    [LocalStorageKeys.IS_INSTITUTIONS_LIST_ON_PARENTS_PAGE_EXPANDED]: false,
    [LocalStorageKeys.IS_INSTITUTIONS_LIST_ON_CHILDREN_PAGE_EXPANDED]: false,
    [LocalStorageKeys.IS_CONTACTS_LIST_ON_CONTACTS_PAGE_EXPANDED]: false,
    [LocalStorageKeys.IS_CONTACTS_LIST_ON_PARENT_PAGE_EXPANDED]: false,
    [LocalStorageKeys.IS_CONTACTS_LIST_ON_CHILD_PAGE_EXPANDED]: false,
  }

  // Method to get the value from localStorage or the default
  static get<T>(key: LocalStorageKeys): T {
    const storedValue = localStorage.getItem(key);
    if (storedValue === null) {
      return this.defaults[key] as T;
    }
    try {
      const parsedValue = JSON.parse(storedValue);
      return parsedValue as T;
    } catch (e) {
      console.error(`Error parsing localStorage value for key: ${key}`, e);
      return this.defaults[key] as T;
    }
  }

  static getCustom(key: string, defaultValue: string) {
    const storedValue = localStorage.getItem(key);
    if (storedValue === null) {
      return defaultValue
    }
    try {
      return JSON.parse(storedValue);
    } catch (e) {
      console.error(`Error parsing localStorage value for key: ${key}`, e);
      return defaultValue;
    }
  }

  // Method to set the value in localStorage
  static set<T>(key: LocalStorageKeys, value: T): void {
    try {
      const valueToStore = `${value}`;
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (e) {
      console.error(`Error setting localStorage value for key: ${key}`, e);
    }
  }

  static setCustom(key: string, value: string): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      console.error(`Error setting localStorage value for key: ${key}`, e);
    }
  }
}
