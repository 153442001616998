import { Link as AmplifyLink } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';
import { useContext, useState } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import General from '../../utils/GeneralUtils';
import Currency from './../Currency';
import { GlobalContext } from '../../global-context';
import { FieldName } from '../../utils/CustomTypes';
import { TableFields } from '../../utils/TableFields';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

export function TableRowDistrict({
  row,
  tableFields
}: {
  row: any,
  tableFields: TableFields
}) {
  const globalContext = useContext(GlobalContext);

  const [isFulltextExpanded, setIsFulltextExpanded] = useState(false)

  return <>
    <div className='results-row-content'>
      <div className="results-row-main-row">
        <div style={{ flex: 1.8 }}>
          {
            tableFields.isFieldVisible(FieldName.NAME) &&
              <>
                <div>
                  <Link to={`/directory/${globalContext.environment.entities.parentInstitution.urlSegment}/${row._id}`} className='main-table-link'>{ row._source['name'] }</Link>
                  <div style={{ marginTop: 5 }}>
                    { [row._source['locCity'] || null, General.getStateName(row._source['locState']) || null].filter(it => !!it).join(', ') }
                  </div>
                </div>
                {
                  (row._source?.phone || row._source?.fax) && <div style={{ marginTop: 8 }} className='desc-2'>
                    {
                      row._source?.phone && <span>
                        Phone { row._source?.phone }
                      </span>
                    }
                    {
                      (row._source?.phone && row._source?.fax) && <span>, </span>
                    }
                    {
                      row._source?.fax && <span>
                        Fax { row._source?.fax }
                      </span>
                    }
                  </div>
                }
              </>
          }
        </div>
        {
          tableFields.isFieldVisible(FieldName.FSCL_REV_TOTAL) &&
            <div style={{ width: '150px' }}>
              <Currency amount={ row._source['fsclRevTotal'] } />
            </div>
        }
        {
          tableFields.isFieldVisible(FieldName.STUDENTS_TOTAL) &&
            <div style={{ width: '120px' }}>
              { row._source['studentsTotal'] }
            </div>
        }
        {
          tableFields.isFieldVisible(FieldName.TOTAL_SCHOOLS) &&
            <div style={{ width: '140px' }}>
              { row._source['totalSchools'] }
            </div>
        }
        {
          tableFields.isFieldVisible(FieldName.WEBSITE) &&
            <div style={{ width: '50px' }}>
              {
                row._source['website'] ?
                  <AmplifyLink
                    target='_blank'
                    href={row._source['website']}
                    title={row._source['website']}
                    style={{ width: 18, height: 18, display: 'block' }}
                    data-tooltip-id="main-tooltip"
                    data-tooltip-content={ row._source['website'] }
                  >
                    <AiOutlineLink size={18} />
                  </AmplifyLink> :
                  <></>
              }
            </div>
        }
      </div>
      <div>
        {
          row._source?.fulltextMatchedUrls?.length ?
            <div style={{ marginRight: 10 }}>
              <span
                className='tiny-button'
                onClick={() => {
                  setIsFulltextExpanded(!isFulltextExpanded)
                }}
              >
                { row._source?.fulltextMatchedUrls?.length } URL{ row._source?.fulltextMatchedUrls?.length === 1 ? '' : 's' } matching fulltext search { isFulltextExpanded ? <BiChevronUp /> : <BiChevronDown /> }
              </span>
            </div> : <></>
        }
        {
          isFulltextExpanded ?
            <div style={{ marginTop: 5 }}>
              {
                row._source?.fulltextMatchedUrls.map((it: any) => (
                  <div key={it} style={{ fontSize: 14 }}>
                    <AmplifyLink
                      target='_blank'
                      href={ it }
                    >
                      { General.ellipsisInMiddle(it, 110) }
                    </AmplifyLink>
                  </div>
                ))
              }
            </div> : <></>
        }
      </div>
    </div>
  </>
}
