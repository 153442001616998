import { AuthSession } from '@aws-amplify/core/dist/esm/singleton/Auth/types';
import { DataStore } from '@aws-amplify/datastore';
import { Link as AmplifyLink, Flex, Image, withAuthenticator, WithAuthenticatorProps } from "@aws-amplify/ui-react";
import { FetchUserAttributesOutput, GetCurrentUserOutput, signUp, SignUpInput } from 'aws-amplify/auth';
import { Component } from 'react';
import Modal from 'react-modal';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import './App.scss';
import { SideNav } from './custom-components/SideNav';
import { TeamDao } from './utils/TeamDao';
import { GlobalContext } from './global-context';
import { Contact } from './pages/Contact';
import { Contacts } from './pages/Contacts';
import { ParentInstitution } from './pages/ParentInstitution';
import { Institutions } from './pages/Institutions';
import { NotFound } from './pages/NotFound';
import { Profile } from './pages/Profile';
import { ChildInstitution } from './pages/ChildInstitution';
import { Settings } from './pages/Settings';
import { TeamPage } from './pages/Team';
import ServiceAgencies from './pages/ServiceAgencies';
import { Prospector } from './pages/Prospector';
import { ProspectorJob } from './pages/ProspectorJob';
import { NewHires } from './pages/NewHires';
import { Home } from './pages/Home';
import { CustomEnvironment, getEnvironment, getFlavor } from './environment';

export interface AuthInfo {
  teamId: string;
  userId: string;
  currentSession: AuthSession;
  currentAuthenticatedUser: GetCurrentUserOutput;
  userAttributes: FetchUserAttributesOutput;
  team: any;
}

interface AppState {
  authInfo: AuthInfo | null;
  environment: CustomEnvironment;
  team: any
}

Modal.setAppElement('#root');

export class App extends Component<WithAuthenticatorProps, AppState> {
  props: WithAuthenticatorProps;

  constructor(props: WithAuthenticatorProps) {
    super(props)
    this.props = props;
    this.state = {
      authInfo: null,
      environment: getEnvironment(),
      team: null,
    }
  }

  async componentDidMount() {
    console.log('%c1/2 Starting DataStore', 'color: pink; font-weight: bold')
    await DataStore.start()
    console.log('%c2/2 Started DataStore', 'color: pink; font-weight: bold')
    // console.log('%c3/4 Waiting for DataStore to be ready', 'color: pink; font-weight: bold')
    // await waitForDataStoreReady()
    // console.log('%c4/4 DataStore is ready', 'color: pink; font-weight: bold')
    const authInfo = await TeamDao.fetchAuthInfo()
    this.onSetState(authInfo)
  }

  onSetState(authInfo: AuthInfo) {
    this.setState({ authInfo })
  }

  render() {
    return (
      <GlobalContext.Provider value={{
        withAuthenticatorProps: this.props,
        authInfo: this.state.authInfo,
        environment: this.state.environment,
        onSetState: this.onSetState.bind(this)
      }}>
        <BrowserRouter>
          <div className="app">
            <SideNav />
            <div className="right-side">
              <Routes>
                <Route path="/home" element={<Home />} />

                <Route path="/" element={<Navigate to={`/directory/contacts${this.state.environment.entities.contact.defaultQueryString}`} />} />
                <Route path="/directory" element={<Navigate to={`/directory/contacts${this.state.environment.entities.contact.defaultQueryString}`} />} />

                <Route path="/directory/contacts" element={<Contacts />} />
                <Route path="/directory/contacts/:id" element={<Contact />} />
                
                <Route path="/directory/schools" element={<Institutions type='CHILD' key='CHILD' />} />
                <Route path="/directory/schools/:id" element={<ChildInstitution />} />
                <Route path="/directory/hospitals" element={<Institutions type='CHILD' key='CHILD' />} />
                <Route path="/directory/hospitals/:id" element={<ChildInstitution />} />

                <Route path="/directory/districts" element={<Institutions type='PARENT' key='PARENT' />} />
                <Route path="/directory/districts/:id" element={<ParentInstitution />} />
                <Route path="/directory/idns" element={<Institutions type='PARENT' key='PARENT' />} />
                <Route path="/directory/idns/:id" element={<ParentInstitution />} />

                {
                  this.state.environment.flags.shouldEnableServiceAgenciesPage &&
                    <Route path="/service-agencies" element={<ServiceAgencies />} />
                }

                <Route path="/team" element={<TeamPage />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/profile" element={<Profile />} />

                {
                  this.state.environment.flags.shouldEnableProspectorPage &&
                    <Route path="/prospector" element={<Prospector />}  />
                }
                {
                  this.state.environment.flags.shouldEnableProspectorPage &&
                    <Route path="/prospector/:id" element={<ProspectorJob />}  />
                }
                {
                  this.state.environment.flags.shouldEnableNewHiresPage &&
                    <Route path="/new-hires" element={<NewHires />}  />
                }

                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </BrowserRouter>
        <ToastContainer
          transition={Slide}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div id='modalroot'></div>
        <Tooltip id="main-tooltip" />
      </GlobalContext.Provider>
    );
  }
}

export default withAuthenticator(App, {
  services: {
    handleSignUp: async (input: SignUpInput) => {
      return signUp({
        username: input.username,
        password: input.password,
        options: {
          ...input.options,
          userAttributes: {
            ...input.options?.userAttributes,
            zoneinfo: getFlavor()
          }
        }
      })
    }
  },
  formFields: {
    signUp: {
      email: {
        order: 1,
      },
      password: {
        order: 2,
      },
      confirm_password: {
        order: 3,
      },
      given_name: {
        placeholder: '',
        label: 'First Name',
        order: 4
      },
      family_name: {
        placeholder: '',
        label: 'Last Name',
        order: 5
      }
    }
  },
  components: {
    Header: () => {
      const environment = getEnvironment()
      return <Flex style={{ marginBottom: 60, marginTop: -30 }} justifyContent='center'>
        <Image src={`/${environment.logo}`} width={environment.logoWidth * 1.25} alt={environment.name} />
      </Flex>
    },
    Footer: () => {
      const environment = getEnvironment()
      return (
        <div style={{ marginTop: 20 }}>
          <div style={{ textAlign: 'center', color: 'var(--amplify-colors-neutral-80)', fontSize: 12, marginTop: 10 }}>
            © 2024 Edulead, Inc. All Rights Reserved.
          </div>
          <div style={{ textAlign: 'center', marginTop: 0 }}>
            <AmplifyLink
              target='_blank'
              href={environment.urlPrivacy}
              style={{ color: 'var(--amplify-colors-neutral-80)', fontSize: 12, marginRight: 5 }}
            >
              Privacy Policy
            </AmplifyLink>
            <AmplifyLink
              target='_blank'
              href={environment.urlTerms}
              style={{ color: 'var(--amplify-colors-neutral-80)', fontSize: 12, marginLeft: 5 }}
            >
              Terms of Service
            </AmplifyLink>
          </div>
        </div>
      );
    }
  }
});
